package com.makeevrserg.koleso.resources

import com.makeevrserg.koleso.resources.images.MRImages
import com.makeevrserg.koleso.resources.strings.MRStrings

public object MR {
  public val string: MRStrings = MRStrings

  public val image: MRImages = MRImages
}
