package com.makeevrserg.koleso.resources.strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String
import kotlin.collections.Map

public object MRStrings {
  private val baseLocale: StringsEn = StringsEn

  private val locales: Map<String, Strings> = mapOf("en" to StringsEn)

  public val app_name: String
    get() = locales[getCurrentLanguageCode()]?.app_name ?: baseLocale.app_name
}
