package com.makeevrserg.koleso.resources.images

import io.github.skeptick.libres.images.Image

public actual object MRImages {
  public actual val cat_black: Image
    get() = "images/cat_black.png"

  public actual val cat_calico: Image
    get() = "images/cat_calico.png"

  public actual val cat_red: Image
    get() = "images/cat_red.png"

  public actual val cat_white: Image
    get() = "images/cat_white.png"

  public actual val ic_splash: Image
    get() = "images/ic_splash.png"

  public actual val img_cat_jellie: Image
    get() = "images/img_cat_jellie.png"

  public actual val img_cat_ocelot: Image
    get() = "images/img_cat_ocelot.png"

  public actual val img_cat_persian: Image
    get() = "images/img_cat_persian.png"

  public actual val img_cat_ragdoll: Image
    get() = "images/img_cat_ragdoll.png"

  public actual val img_cat_red: Image
    get() = "images/img_cat_red.png"

  public actual val img_cat_shorthair: Image
    get() = "images/img_cat_shorthair.png"

  public actual val img_cat_siamese: Image
    get() = "images/img_cat_siamese.png"

  public actual val img_cat_tabby: Image
    get() = "images/img_cat_tabby.png"

  public actual val img_cat_tuxedo: Image
    get() = "images/img_cat_tuxedo.png"

  public actual val img_cat_white: Image
    get() = "images/img_cat_white.png"

  public actual val img_gold: Image
    get() = "images/img_gold.png"

  public actual val img_lava_bucket: Image
    get() = "images/img_lava_bucket.png"
}
